@tailwind base;
@tailwind components;
@tailwind utilities;

@import "buttons";
@import "forms";

.no-cursor::selection {
  color: transparent;
  background: transparent;
}

$header-height: 2.5rem;

.h-header {
  height: $header-height;
}

.h-content {
  height: calc(100% - $header-height);
}

.max-h-content {
  max-height: calc(100% - $header-height);
}



$padding-modal: 1rem;
$padding-model-sm: 1.5rem;
$padding-model-md: 5rem;

.modal-search--padding {
  padding: $padding-modal;

  @screen sm {
    padding: $padding-model-sm;
  }

  @screen md {
    padding: $padding-model-md;
  }
}

.modal-search--height {
  min-height: 18rem;
  max-height: calc(100dvh - $padding-modal * 2);

  @screen sm {
    max-height: calc(100dvh - $padding-model-sm * 2);
  }

  @screen md {
    max-height: calc(100dvh - $padding-model-md * 2);
  }
}

.modal-search__striped-list {
  li {
    @apply cursor-default select-none rounded-md text-gray-400 outline-transparent;
  }

  li:nth-child(odd) {
    @apply cursor-default select-none rounded-md text-gray-400 outline-transparent bg-gray-100;
  }
}


.bg-stripes {
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 50%, #ccc 50%, #ccc 75%, transparent 75%, transparent);
  background-size: 30px 30px; // Ajusta el tamaño y espaciado de las rayas
}

.padre {
  .hijo {
    @apply text-white;
  }
}
