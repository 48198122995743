
.btn-base {
  @apply inline-flex w-full px-3 py-2 justify-center rounded-md shadow-sm
  text-white text-sm font-semibold
  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
  disabled:cursor-not-allowed disabled:opacity-30;
}

@mixin button-variant($color-base, $color-hover) {
  @apply btn-base #{$color-base};
  &:hover {
    @apply #{$color-hover};
  }
  &:focus-visible {
    @apply #{$color-base};
  }
  &:disabled:hover {
    @apply #{$color-base};
  }
}

$color-primary-base: bg-indigo-600;
$color-primary-hover: bg-indigo-700;
.btn-primary {
  @include button-variant($color-primary-base, $color-primary-hover);
}

$color-secondary-base: bg-gray-500;
$color-secondary-hover: bg-gray-600;
.btn-secondary {
  @include button-variant($color-secondary-base, $color-secondary-hover);
}

$color-success-base: bg-emerald-500;
$color-success-hover: bg-emerald-600;
.btn-success {
  @include button-variant($color-success-base, $color-success-hover);
}

$color-danger-base: bg-rose-500;
$color-danger-hover: bg-rose-600;
.btn-danger {
  @include button-variant($color-danger-base, $color-danger-hover);
}

$color-warning-base: bg-orange-400;
$color-warning-hover: bg-orange-500;
.btn-warning {
  @include button-variant($color-warning-base, $color-warning-hover);
}

$color-info-base: bg-sky-400;
$color-info-hover: bg-sky-500;
.btn-info {
  @include button-variant($color-info-base, $color-info-hover);
}

.btn-sm {
  @apply text-xs px-2.5 py-1.5 #{!important};
}

.btn-form-action {
  @apply max-w-24;
}
